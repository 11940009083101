var clipCtl, headerBgc, jsClip, resizeWindow, searchMove, slideMenu, triggerClip;

window.addEventListener('scroll', (function() {
  headerBgc();
}), false);

headerBgc = function() {
  var target, y;
  y = document.documentElement.scrollTop || document.body.scrollTop;
  target = document.getElementsByTagName('header')[0];
  if ((y <= 0 && hasClass(target, 'is-scroll')) || (y > 0 && !hasClass(target, 'is-scroll'))) {
    target.classList.toggle('is-scroll');
  }
};

slideMenu = function() {
  var content, empty, side;
  side = document.getElementById('js-side_bar');
  empty = document.getElementById('js-empty_btn');
  content = document.getElementById('js-pagetop');
  empty.classList.toggle('is-open');
  content.classList.toggle('is-open');
  side.classList.toggle('is-open');
};

searchMove = function() {
  var content;
  content = document.getElementById('js-pagetop');
  content.classList.toggle('is-search');
};

Element.prototype.topSlider = function() {
  var $currentPage, $items, $single, $slider, $wrapper, animateMove, animateSlider, firstNodeCloned, gotoPage, isTouch, justMove, lastNodeCloned, movingSlider, onlyMove, pages, singleWidth, startSlider, stopSlider, touchFlg, visible;
  $wrapper = document.getElementById('js-slide-viewer');
  $slider = document.getElementById('js-slide-inner');
  $items = document.getElementsByClassName('js-slide-link');
  $single = $slider.firstElementChild;
  singleWidth = $single.clientWidth;
  visible = 1;
  $currentPage = 1;
  if (hasClass($single, 'js-cloned')) {
    pages = Math.ceil(($items.length - 2) / visible);
  } else {
    pages = Math.ceil($items.length / visible);
    firstNodeCloned = $single.cloneNode(true);
    firstNodeCloned.className += ' js-cloned';
    lastNodeCloned = $slider.lastElementChild.cloneNode(true);
    lastNodeCloned.className += ' js-cloned';
    $slider.insertBefore(lastNodeCloned, $slider.firstElementChild);
    $slider.insertBefore(firstNodeCloned, $slider.lastElementChild.nextSibling);
  }
  onlyMove = function(size) {
    $slider.style['-webkit-transform'] = "translate(" + size + "px)";
    $slider.style['-ms-transform'] = "translate(" + size + "px)";
    $slider.style['transform'] = "translate(" + size + "px)";
  };
  justMove = function(size) {
    $slider.style['-webkit-transition'] = "transform 0ms";
    $slider.style['-ms-transition'] = "transform 0ms";
    $slider.style['transition'] = "transform 0ms";
    onlyMove(size);
  };
  animateMove = function(size) {
    $slider.style['-webkit-transition'] = "transform 400ms";
    $slider.style['-ms-transition'] = "transform 400ms";
    $slider.style['transition'] = "transform 400ms";
    onlyMove(size);
  };
  justMove(-singleWidth * visible);
  animateSlider = function(moving, page) {
    var addClassFunc;
    addClassFunc = function() {
      if (page > pages) {
        justMove(-singleWidth * visible);
      } else if (page === 0) {
        justMove(-singleWidth * visible * pages);
      }
      $slider.removeEventListener('webkitTransitionEnd', addClassFunc, false);
      $slider.removeEventListener('transitionend', addClassFunc, false);
    };
    if (hasClass($slider, 'js-animated')) {
      return false;
    } else {
      $slider.classList.add('js-animated');
      animateMove(-moving);
      $slider.addEventListener('webkitTransitionEnd', addClassFunc, false);
      $slider.addEventListener('transitionend', addClassFunc, false);
      return $slider.classList.remove('js-animated');
    }
  };
  gotoPage = function(page) {
    var dir, leftMove, moveTo, mvLeft, n;
    dir = (page < $currentPage ? -1 : 1);
    n = Math.abs($currentPage - page);
    leftMove = singleWidth * dir * n;
    mvLeft = $currentPage * singleWidth;
    moveTo = mvLeft + leftMove;
    animateSlider(moveTo, page);
    if (page > pages) {
      page = 1;
    } else if (page === 0) {
      page = pages;
    }
    $currentPage = page;
  };
  movingSlider = void 0;
  startSlider = function() {
    movingSlider = setInterval(function() {
      gotoPage($currentPage + 1);
    }, 4000);
  };
  stopSlider = function() {
    clearInterval(movingSlider);
  };
  startSlider();
  window.addEventListener('orientationchange', function() {
    resizeWindow();
    document.getElementById('js-slider').topSlider();
  });
  isTouch = 'ontouchstart' in window;
  touchFlg = 0;
  $slider.addEventListener('touchstart', (function(e) {
    stopSlider();
    this.pageX = isTouch ? e.changedTouches[0].pageX : e.pageX;
    this.pageY = isTouch ? e.changedTouches[0].pageY : e.pageY;
    this.basePoint = this.left = parseFloat(getTranslateX($slider));
    this.touched = true;
    touchFlg = 1;
  }), false);
  $slider.addEventListener('touchmove', function(e) {
    var moveRate, moveX, moveY;
    if (!this.touched) {
      return;
    }
    this.left = parseFloat(getTranslateX($slider)) + (this.pageX - (isTouch ? e.changedTouches[0].pageX : e.pageX));
    justMove(-this.left);
    moveX = this.pageX - (isTouch ? e.changedTouches[0].pageX : e.pageX);
    moveY = this.pageY - (isTouch ? e.changedTouches[0].pageY : e.pageY);
    moveRate = moveX / moveY;
    if (moveRate > Math.tan(15 * Math.PI / 180)) {
      e.preventDefault();
    }
    this.pageX = isTouch ? e.changedTouches[0].pageX : e.pageX;
    this.pageY = isTouch ? e.changedTouches[0].pageY : e.pageY;
    touchFlg = 2;
  });
  $slider.addEventListener('touchend', function(e) {
    var addClassFunc;
    if (!this.touched) {
      return;
    }
    if (touchFlg === 2) {
      touchFlg = 3;
    } else {
      touchFlg = 0;
    }
    this.touched = false;
    if (this.basePoint - this.left > 80) {
      gotoPage($currentPage - 1);
    } else if (this.basePoint - this.left < -80) {
      gotoPage($currentPage + 1);
    } else {
      addClassFunc = function() {
        $slider.removeEventListener('webkitTransitionEnd', addClassFunc, false);
        $slider.removeEventListener('transitionend', addClassFunc, false);
      };
      if (hasClass($slider, 'js-animated')) {
        return false;
      } else {
        $slider.classList.add('js-animated');
        animateMove(-this.basePoint);
        $slider.addEventListener('webkitTransitionEnd', addClassFunc, false);
        $slider.addEventListener('transitionend', addClassFunc, false);
        $slider.classList.remove('js-animated');
      }
    }
    touchFlg = 0;
    startSlider();
  });
};

window.onload = function() {
  var slid;
  if (slid = document.getElementById('js-slider')) {
    resizeWindow();
    slid.topSlider();
  }
  if (window.location.hash === "#js-success-signup") {
    showSuccessLogin();
  }
};

resizeWindow = function() {
  var contentWidth, element, i, slides, slidesNum, target, topInner;
  topInner = document.getElementById('js-slide-inner');
  element = document.getElementsByClassName('l-content')[0];
  contentWidth = trueWidth(element);
  slides = document.getElementsByClassName('js-slide-link');
  slidesNum = slides.length;
  if (hasClass(topInner, 'is-first')) {
    topInner.classList.remove('is-first');
  }
  topInner.style.width = (contentWidth * (slidesNum + 2)) + "px";
  i = 0;
  while (i < slidesNum) {
    target = slides[i];
    target.style.width = contentWidth + "px";
    i++;
  }
};

document.getElementById('js-btn--menu').addEventListener('click', function(e) {
  e.preventDefault();
  slideMenu();
});

document.getElementById('js-btn--search').addEventListener('click', function(e) {
  e.preventDefault();
  searchMove();
});

document.getElementById('js-btn--back').addEventListener('click', function(e) {
  e.preventDefault();
  searchMove();
});

document.getElementById('js-empty_btn').addEventListener('click', function(e) {
  e.preventDefault();
  slideMenu();
});

if (jsClip = document.getElementById('js-clip_ctl')) {
  document.getElementById('js-clip_ctl').addEventListener('click', function(e) {
    clipCtl(e.target);
  });
}

if (jsClip = document.getElementById('js-clip_ctl--bottom')) {
  document.getElementById('js-clip_ctl--bottom').addEventListener('click', function(e) {
    clipCtl(e.target);
  });
}

clipCtl = function(target) {
  var entry_id, url;
  if (!target.closest('[data-entry_id]')) {
    location.reload();
    return false;
  }
  entry_id = target.closest('[data-entry_id]').getAttribute('data-entry_id');
  if (entry_id === null) {
    location.reload();
    return false;
  }
  url = "/mypage/ctl_clip?entry_id=" + entry_id;
  getAjax(url, triggerClip);
};

triggerClip = function(xhttp) {
  var btn, btnBottom, data;
  data = JSON.parse(xhttp.responseText);
  notificationFlash({
    message: data.message,
    notifiType: data.type
  });
  btn = document.getElementById('js-clip_ctl');
  btnBottom = document.getElementById('js-clip_ctl--bottom');
  if (data.status === 'create') {
    btn.innerHTML = '<span class="fas fa-check-circle"/>';
    btnBottom.classList.add('is-active');
    btnBottom.innerHTML = "<span class='fa fa-paperclip p-article__social--bottom__txt'></span>クリップを外す";
  } else if (data.status === 'delete') {
    btn.innerHTML = '<span class="far fa-check-circle"/>';
    btnBottom.classList.remove('is-active');
    btnBottom.innerHTML = "<span class='fa fa-paperclip p-article__social--bottom__txt'></span>クリップする";
  }
};
