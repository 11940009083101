require("@rails/activestorage").start()
import LazyLoad from 'vanilla-lazyload';
window.LazyLoad = LazyLoad;
require("./_utility")
require("./mobile/_element")
// require("@fortawesome/fontawesome-free/js/fontawesome.min");
// require("@fortawesome/fontawesome-free/js/regular.min");
// require("@fortawesome/fontawesome-free/js/brands.min");
// require("@fortawesome/fontawesome-free/js/solid.min");
import './mobile/_fontawesome'
import picturefill from "picturefill";
picturefill();
